import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')

class setupTaxRatesAmounts extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:21,ratesamounts:'',Resp:'',
			total00:'',total01:'',total02:'',total03:'',total04:'',total10:'',total11:'',total12:'',total13:'',total14:'',
			total20:'',total21:'',total22:'',total23:'',total24:'',yearclaimcode:'',indexvalue:'',indexyear:'',
			user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);		
		this.handleIndexChange=this.handleIndexChange.bind(this)		
		this.handleYearChange=this.handleYearChange.bind(this)
		this.handleManageClaimChange=this.handleManageClaimChange.bind(this)		
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {			
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Provs:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});			
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
	}	
	handleYearChange = event=>{		
		this.setState({Resp:""});
		this.setState({total00:""}); this.setState({total01:""}); this.setState({total02:""});this.setState({total03:""});this.setState({total04:""});
		this.setState({total10:""}); this.setState({total11:""}); this.setState({total12:""});this.setState({total13:""});this.setState({total14:""});
		this.setState({total20:""}); this.setState({total21:""}); this.setState({total22:""});this.setState({total23:""});this.setState({total24:""});
		this.setState({indexvalue:""})	
		this.setState({indexyear:""})
		const {name, value} = event.target				
		this.setState({[name]: value})	
		if (event.target.value==thisyear){
			this.setState({indexyear:lastyear})
		} else if (event.target.value==nextyear){
			this.setState({indexyear:thisyear})
		}	
		if(this.state.ratesamounts ==2 || this.state.ratesamounts ==3) {
			const userObject={					
					STATE:'ON',
					lastYEAR: event.target.value,
					thisYEAR: event.target.value}
			axios.post(process.env.REACT_APP_API_ENV+'api/updateohp.php', userObject)
				.then(result => {							
					if(result.data[0][0] != 'No')	{
						this.setState({total00:result.data.filter(sub=>sub[2]=='0').map(sub=>sub[3]).[0]});this.setState({total01:result.data.filter(sub=>sub[2]=='0').map(sub=>sub[4]).[0]}); this.setState({total02:result.data.filter(sub=>sub[2]=='0').map(sub=>sub[5]).[0]});
						this.setState({total03:result.data.filter(sub=>sub[2]=='0').map(sub=>sub[6]).[0]});this.setState({total04:result.data.filter(sub=>sub[2]=='0').map(sub=>sub[7]).[0]});
						this.setState({total10:result.data.filter(sub=>sub[2]=='1').map(sub=>sub[3]).[0]});this.setState({total11:result.data.filter(sub=>sub[2]=='1').map(sub=>sub[4]).[0]}); this.setState({total12:result.data.filter(sub=>sub[2]=='1').map(sub=>sub[5]).[0]});
						this.setState({total13:result.data.filter(sub=>sub[2]=='1').map(sub=>sub[6]).[0]});this.setState({total14:result.data.filter(sub=>sub[2]=='1').map(sub=>sub[7]).[0]});
						this.setState({total20:result.data.filter(sub=>sub[2]=='2').map(sub=>sub[3]).[0]});this.setState({total21:result.data.filter(sub=>sub[2]=='2').map(sub=>sub[4]).[0]}); this.setState({total22:result.data.filter(sub=>sub[2]=='2').map(sub=>sub[5]).[0]});
						this.setState({total23:result.data.filter(sub=>sub[2]=='2').map(sub=>sub[6]).[0]});this.setState({total24:result.data.filter(sub=>sub[2]=='2').map(sub=>sub[7]).[0]});
					} else {this.setState({indexvalue:'No'})}
				})	
		}		
	}

	
	handleManageClaimChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})
		this.setState({yearclaimcode:''})		
		this.setState({Resp:""});		
	}
	
	handleIndexChange = event=>{	
		this.setState({total00:""}); this.setState({total01:""}); this.setState({total02:""});this.setState({total03:""});this.setState({total04:""});this.setState({total05:""});this.setState({total06:""});
		this.setState({total10:""}); this.setState({total11:""}); this.setState({total12:""});this.setState({total13:""});this.setState({total14:""});this.setState({total15:""});this.setState({total16:""});
		this.setState({total20:""}); this.setState({total21:""}); this.setState({total22:""});this.setState({total23:""});this.setState({total24:""});this.setState({total25:""});this.setState({total26:""});		
		const {name, value} = event.target				
		this.setState({[name]: value})		
		if(event.target.value=="Yes") {
			const userObject={					
					STATE:'ON',
					lastYEAR: this.state.indexyear,
					thisYEAR: this.state.yearclaimcode}
			axios.post(process.env.REACT_APP_API_ENV+'api/updateohp.php', userObject)
				.then(result => {							
					if(result.data[0][0] != 'No')	{
						this.setState({total00:result.data.filter(sub=>sub[2]=='0').map(sub=>sub[3]).[0]});this.setState({total01:result.data.filter(sub=>sub[2]=='0').map(sub=>sub[4]).[0]}); this.setState({total02:result.data.filter(sub=>sub[2]=='0').map(sub=>sub[5]).[0]});
						this.setState({total03:result.data.filter(sub=>sub[2]=='0').map(sub=>sub[6]).[0]});this.setState({total04:result.data.filter(sub=>sub[2]=='0').map(sub=>sub[7]).[0]});
						this.setState({total10:result.data.filter(sub=>sub[2]=='1').map(sub=>sub[3]).[0]});this.setState({total11:result.data.filter(sub=>sub[2]=='1').map(sub=>sub[4]).[0]}); this.setState({total12:result.data.filter(sub=>sub[2]=='1').map(sub=>sub[5]).[0]});
						this.setState({total13:result.data.filter(sub=>sub[2]=='1').map(sub=>sub[6]).[0]});this.setState({total14:result.data.filter(sub=>sub[2]=='1').map(sub=>sub[7]).[0]});
						this.setState({total20:result.data.filter(sub=>sub[2]=='2').map(sub=>sub[3]).[0]});this.setState({total21:result.data.filter(sub=>sub[2]=='2').map(sub=>sub[4]).[0]}); this.setState({total22:result.data.filter(sub=>sub[2]=='2').map(sub=>sub[5]).[0]});
						this.setState({total23:result.data.filter(sub=>sub[2]=='2').map(sub=>sub[6]).[0]});this.setState({total24:result.data.filter(sub=>sub[2]=='2').map(sub=>sub[7]).[0]});
					} else {this.setState({indexvalue:'No'})}
				})	
		}
		this.setState({Resp:""});		
	}
		
	handleSubmit = event => {		
		event.preventDefault()		
		const {ratesamounts,total00,total01,total02,total03,total04,total10,total11,total12,total13,
				total14,total20,total21,total22,total23,total24,yearclaimcode,indexvalue} = this.state			
		if (this.state.ratesamounts == "") {
			alert('Please select manage OHP type, Add, Update or Delete')
			return false
		}
		if(this.state.ratesamounts==1)  {						
			if(!yearclaimcode) {
				alert('Please select year for Ontario Health Premium')
				return false
			}
			if(!indexvalue) {
				alert('Please select whether use last year data or not')
				return false
			}		
			if(!total00) {
				alert('Please enter first annual taxable income range')
				return false
			}						
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,					
				yearclaimcode: this.state.yearclaimcode,
				indexvalue:this.state.indexvalue,				
				total00:this.state.total00,total01:this.state.total01,total02:this.state.total02,total03:this.state.total03,total04:this.state.total04,
				total10:this.state.total10,total11:this.state.total11,total12:this.state.total12,total13:this.state.total13,total14:this.state.total14,
				total20:this.state.total20,total21:this.state.total21,total22:this.state.total22,total23:this.state.total23,total24:this.state.total24
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.ratesamounts==2)  {						
			if(!yearclaimcode) {
				alert('Please select year for OHP updating')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,					
				yearclaimcode: this.state.yearclaimcode,
				total00:this.state.total00,total01:this.state.total01,total02:this.state.total02,total03:this.state.total03,total04:this.state.total04,
				total10:this.state.total10,total11:this.state.total11,total12:this.state.total12,total13:this.state.total13,total14:this.state.total14,
				total20:this.state.total20,total21:this.state.total21,total22:this.state.total22,total23:this.state.total23,total24:this.state.total24
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.ratesamounts==3)  {					
			if(!yearclaimcode) {
				alert('Please select year')
				return false
			}		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				ratesamounts:this.state.ratesamounts,	
				employeeUpdate:this.state.employeeUpdate,
				yearclaimcode: this.state.yearclaimcode,
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {						
		return (					
			<div>      				
				<CommonHeader titles="Tax Health Premium"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage Ontario Health Premium</h2></label>							
						<select name="ratesamounts" id="ratesamounts" style={{width:'150px'}} value={this.state.ratesamounts} onChange={this.handleManageClaimChange}>
							<option value="">--Manage Type--</option>
							<option value="1">Add New OHP</option>															
							<option value="2">Update OHP</option>												
							<option value="3">Remove OHP</option>		
						</select><br/><br/>	
					</div>	
					{(()=>{
						{/*add/update/remove new OHP*/}
						if(this.state.ratesamounts != "") {return(<div>														
							<label><h2>Select Year</h2></label>							
							<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleYearChange}>
								<option value="">--Select Year--</option>									
								{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}									
							</select><br/><br/>	
							{(()=>{if(this.state.yearclaimcode != "" && this.state.ratesamounts==1) {return(<div>
									<label><h2>Last Year Data</h2></label>							
									<select id="indexvalue" name="indexvalue" value = {this.state.indexvalue} style={{width:'160px'}} onChange={this.handleIndexChange}>
										<option value="">--Apply--</option>									
										<option value="No">No, self entry</option>									
										<option value="Yes">Yes, use last year data</option>									
									</select><br/><br/>									
									<h2>{(this.state.ratesamounts==2 && this.state.yearclaimcode !="") ? "Please modify OHP and update":""}</h2><br/>
									<h2>{(this.state.ratesamounts==3 && this.state.yearclaimcode !="") ? "Please look at OHP values carefully and determine the removing":""}</h2><br/>
									<h2>{(this.state.ratesamounts==1 && this.state.yearclaimcode !="") ? "Ontario Health Premium":""}</h2><br/><br/>
								</div>)}
							})()}																												 						
						</div>)} 
					})()}
					{/*Add/update/remove rates and amounts*/}
					{(()=>{if(this.state.ratesamounts != "" && this.state.yearclaimcode !="") {return(<div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'150px'}}>*</h2>
								<h2 style={{width:'150px'}}>Phase 1</h2>
								<h2 style={{width:'150px'}}>Phase 2</h2>
								<h2 style={{width:'150px'}}>Phase 3</h2>
								<h2 style={{width:'150px'}}>Phase 4</h2>
								<h2 style={{width:'150px'}}>Phase 5</h2>								
							</div><br/><br/>
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'150px'}}>Annual Taxable Income</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total00" value={this.state.total00} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total01" value={this.state.total01} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total02" value={this.state.total02} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total03" value={this.state.total03} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total04" value={this.state.total04} onChange={this.handleChange}/></h2>								
							</div><br/><br/>			
						</div>
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'150px'}}>Health Premium</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total10" value={this.state.total10} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total11" value={this.state.total11} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total12" value={this.state.total12} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total13" value={this.state.total13} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total14" value={this.state.total14} onChange={this.handleChange}/></h2>								
							</div><br/><br/>			
						</div>				
						<div className="label-wrap">
							<div className="label">					
								<h2 style={{width:'150px'}}>Health Premium Percent</h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total20" value={this.state.total20} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total21" value={this.state.total21} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total22" value={this.state.total22} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total23" value={this.state.total23} onChange={this.handleChange}/></h2>
								<h2 style={{width:'150px'}}><input type="text" style={{width:'100px'}} name="total24" value={this.state.total24} onChange={this.handleChange}/></h2>								
							</div><br/><br/>			
						</div>										
						<h2>{(this.state.indexvalue!="No" && this.state.indexvalue!="" && this.state.ratesamounts==1)? "These values are populated from "+this.state.indexyear+" data. If no changes required, please click Submit button to add them to the system":""}</h2><br/><br/>
						<h1>{this.state.Resp}</h1><br/><br/>
						</div>)}
					})()}	
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupTaxRatesAmounts;