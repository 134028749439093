import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class setupTaxAmounts extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:12,managementitem:'', Resp:'',Pro:[],employeeUpdate:'', incomerange:'',incomerangede:'',
		noncredit:'',yearclaimcode:'',AddIndex:'', DBYear:'', 
		user:localStorage.getItem('tokens'),names:localStorage.getItem('names')};
		this.handleChange=this.handleChange.bind(this);
		this.handleManageChange=this.handleManageChange.bind(this)
		this.handleUpdateChange=this.handleUpdateChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {						
				this.setState({Pro:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});			
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});		
	}	
	handleManageChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})	
		this.setState({employeeUpdate:""});	
		this.setState({yearclaimcode:''})
		this.setState({basicamount:''})		
		this.setState({incomerange:''})
		this.setState({incomerangede:''})			
		this.setState({Resp:""});	
		this.setState({AddIndex:""});
	}
	handleUpdateChange = event=>{		
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({basicamount:''})
		this.setState({incomerange:''})
		this.setState({incomerangede:''})
		this.setState({AddIndex:""})
		this.setState({DBYear:""})		
		this.setState({noncredit:""})	
		const userObject={state:event.target.value,
						  year:this.state.yearclaimcode,
						  TaxAction:this.state.managementitem}
		axios.post(process.env.REACT_APP_API_ENV+'api/updatetaxamounts.php',userObject)
			.then(result => {
				if ((result.data[0] == 'No') && this.state.managementitem == 1) {
					this.setState({AddIndex:'NO'})
				} else if ((result.data[0] === 'No') && (this.state.managementitem === 2 || this.state.managementitem === 3)) {
					this.setState({indexcomms:'Sorry, please add values first'})
				} else if (result.data[0] != 'No') {
					this.setState({AddIndex:'YES'})
					this.setState({DBYear:result.data[0]})
					this.setState({basicamount:result.data[2]})
					this.setState({incomerange:result.data[3]})
					this.setState({noncredit:result.data[4]})
					this.setState({incomerangede:result.data[5]})
				} 
			})		
		
		this.setState({Resp:""});		
	}
	handleYearChange = event => {
		const {name, value} = event.target				
		this.setState({[name]: value})			
		this.setState({employeeUpdate:''})
		this.setState({Resp:""});
	}
	handleSubmit = event => {		
		event.preventDefault()		
		const {managementitem,basicamount,employeeUpdate,incomerange,incomerangede,yearclaimcode,noncredit} = this.state				
		if (this.state.managementitem == "") {
			alert('Please select action type: Add, Update or Delete')
			return false
		}		
		if(this.state.managementitem == "1")  {									
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}
			if(!basicamount ) {
				alert('Please enter Claim Code 2 Amount --- From')
				return false
			}			
			if(!incomerange) {
				alert('Please enter Claim Code Chart net income range')
				return false
			}
			if(!incomerangede) {
				alert('Please enter tax Index')
				return false
			}
			if(!noncredit) {
				alert('Please enter non refundable tax credit')
				return false
			} 		
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate,
				basicamount:this.state.basicamount,				
				incomerange:this.state.incomerange,
				incomerangede:this.state.incomerangede,
				noncredit:this.state.noncredit
				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} if(this.state.managementitem == "2")  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}
			if(!basicamount ) {
				alert('Please enter claim code 2 amount')
				return false
			}												
			if(!incomerange) {
				alert('Please enter Claim Code Chart net income range')
				return false
			}
			if(!incomerangede) {
				alert('Please enter tax Index')
				return false
			}
			if(!noncredit) {
				alert('Please enter non refundable tax credit')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate,
				basicamount:this.state.basicamount,				
				incomerange:this.state.incomerange,
				incomerangede:this.state.incomerangede,
				noncredit:this.state.noncredit
				
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {					
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} if(this.state.managementitem == "3")  {						
			if(!yearclaimcode) {
				alert('Please select Year')
				return false
			}
			if(!employeeUpdate) {
				alert('Please select Federal or Province')
				return false
			}	
			const userObject={			
				adminitem:this.state.adminitem,		
				user:this.state.user,
				names:this.state.names,
				managementitem:this.state.managementitem,	
				yearclaimcode:this.state.yearclaimcode,
				employeeUpdate:this.state.employeeUpdate
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {		
		return (		
			<div>      		
				<CommonHeader titles="Tax"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Tax Amounts</h2></label>							
						<select name="managementitem" id="managementitem" style={{width:'150px'}} value={this.state.managementitem} onChange={this.handleManageChange}>
							<option value="">--Tax Amount Process--</option>
							<option value="1">Set up Tax Amounts</option>															
							<option value="2">Update Tax Amounts</option>												
							<option value="3">Remove Tax Amounts</option>		
						</select><br/><br/>	
						{(()=>{if(this.state.managementitem !== "") {return(<div>						
								<label><h2>Select Year</h2></label>							
								<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleYearChange}>
									<option value="">--Select Year--</option>									
									{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}									
								</select><br/><br/>
								{(()=>{						
									if(this.state.yearclaimcode !=="" ) {return(<div>
										<label><h2>Choose Federal or Province</h2></label>							
										<select id="employeeUpdate" name="employeeUpdate" value = {this.state.employeeUpdate} style={{width:'160px'}} onChange={this.handleUpdateChange}>
											<option value="">--Federal or Province--</option>									
											{this.state.Pro.filter(sub=>sub[0] !='QC').map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}			
										</select><br/><br/>
									</div>)}
								})()}
							</div>)} 							
						})()}												
					</div>	
					{(()=>{{/*set up/update/remove tax amounts*/}
						if(this.state.managementitem != "" && this.state.employeeUpdate != "") {return(<div>																					
							{(()=>{if(this.state.managementitem == 1 && this.state.AddIndex == "YES") {return(<div>
								<h2>{"The following values are from "+this.state.DBYear+", please update these values if necessary!"}</h2><br/><br/>
							</div>)} else if(this.state.managementitem == 2 && this.state.AddIndex == "YES") {return(<div>
								<h2>{"The following values are from "+this.state.DBYear+", if these values are not selected year values, please add values for the year first."}</h2><br/><br/>
							</div>)}})()}						
							<div className="forinput">
								<label className="labelname">Claim Code 2 Amount: </label>
								<input type="text" name ="basicamount" id="basicamount" style={{width:'150px'}} value={this.state.basicamount} onChange={this.handleChange} />
							</div><br/>	
							<div className="forinput">
								<label className="labelname">Claim Code Chart Income Range <br/>(<small><b>Claim Code 2 - 1 value</b>)</small></label>
								<input type="text" name ="incomerange" id="incomerange" style={{width:'150px'}} value={this.state.incomerange} onChange={this.handleChange} />
							</div><br/>	
							<div className="forinput">
								<label className="labelname">Index Value: </label>
								<input type="text" name ="incomerangede" id="incomerangede" style={{width:'150px'}} value={this.state.incomerangede} onChange={this.handleChange} />
							</div><br/>						
							<div className="forinput">
									<label className="labelname">Non Refundable Tax Credit: <br/>(<small><b>Table 8.1 R or V value</b>)</small></label>
									<input type="text" name ="noncredit" id="noncredit" style={{width:'150px'}} value={this.state.noncredit} onChange={this.handleChange} />
							</div><br/>	
							<h1>{this.state.Resp}</h1><br/><br/>						
						</div>)} 
					})()}					
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupTaxAmounts;