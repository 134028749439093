import CommonHeader from '../clients/commons/Head'
import React from 'react'
import '../main.css'
import axios from 'axios'
import dayjs from 'dayjs';
const thisyear=dayjs().format('YYYY')
const lastyear=dayjs().subtract(1,'year').format('YYYY')
const nextyear=dayjs().add(1,'year').format('YYYY')


class setupPayRollYear extends React.Component {	  
	constructor(props) {
		super(props);
		this.state = {adminitem:10,taxindex:'',yearclaimcode:'',stateID:'',Provs:[],indexcomms:'',
					user:localStorage.getItem('tokens'),names:localStorage.getItem('names'),
					yearIndex:'',OptionTax:'', AddIndex:''};					
		this.handleChange=this.handleChange.bind(this);
		this.handleClaimChange=this.handleClaimChange.bind(this)
		this.handleSubmit=this.handleSubmit.bind(this)			
	}
	componentDidMount() {					
		const para={user:this.state.user,names:this.state.names}		
		axios.post(process.env.REACT_APP_API_ENV+'api/provinces.php')
			.then(result => {		
				this.setState({Provs:result.data});
		}).catch((error) => {
			console.log('error ' + error);   
		});					
	}
	handleChange = event => {			
		const {name, value} = event.target				
		this.setState({[name]: value})		
		this.setState({Resp:""});
		this.setState({indexcomms:""});		
	}
	
	
	handleClaimChange = event=>{
		this.setState({stateab:''})
		this.setState({statebc:''})
		this.setState({statemb:''})
		this.setState({statenb:''})
		this.setState({statenl:''})
		this.setState({statent:''})
		this.setState({statens:''})		
		this.setState({yearIndex:''})
		this.setState({indexcomms:""})		
		this.setState({AddIndex:''})
		this.setState({stateID:''})
		const {name, value} = event.target				
		this.setState({[name]: value})
		if(event.target.value !=""){
			const userObject={stateID:event.target.value,
							  user:this.state.user,
							  YEAR: this.state.yearclaimcode,
							  OptionTax:this.state.taxindex}
			axios.post(process.env.REACT_APP_API_ENV+'api/updateYearIndex.php',userObject)
				.then(result => {
					if (result.data[0] == 'No' && this.state.taxindex == 1) {
						this.setState({AddIndex:'NO'})
					} else if (result.data[0] == 'No' && this.state.taxindex == 2) {
						this.setState({indexcomms:'Sorry, no BPA formula for this year, please add BPA formual first'})
						this.setState({AddIndex:'NO'})
					} else if (result.data[0] != 'No') {
						this.setState({updateindexvalue:result.data})
						this.setState({AddIndex:'YES'})
						this.setState({stateab:result.data[2]})
						this.setState({statebc:result.data[3]})
						this.setState({statemb:result.data[4]})
						this.setState({statenb:result.data[5]})
						this.setState({statenl:result.data[6]})
						this.setState({statens:result.data[7]})
						this.setState({statent:result.data[8]})
						this.setState({yearIndex:result.data[1]})
					} 						
				})		
		}
		
		this.setState({Resp:""});
	}
	
	handleSubmit = event => {		
		event.preventDefault()		
		const {taxindex,yearclaimcode,stateID, stateab,statebc,statemb,statenb,statenl,statent,statens} = this.state			
		if (this.state.taxindex == "") {
			alert('Please select BAP formula type, Add, Update or Remove?')
			return false
		}
		if(this.state.taxindex==1)  {						
			if(!yearclaimcode) {
				alert('Please select year')
				return false
			}	
			if(!stateID) {
				alert('Please select Federal or Province')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				taxindex:this.state.taxindex,					
				yearclaimcode: this.state.yearclaimcode,
				stateID:this.state.stateID,
				stateab:this.state.stateab,
				statebc:this.state.statebc,
				statemb:this.state.statemb,
				statenb:this.state.statenb,
				statenl:this.state.statenl,
				statent:this.state.statent,
				statens:this.state.statens							
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.taxindex==2)  {						
			if(!yearclaimcode) {
				alert('Please select year')
				return false
			}		
			if(!stateID) {
				alert('Please select Federal or Province')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				taxindex:this.state.taxindex,					
				yearclaimcode: this.state.yearclaimcode,
				stateID:this.state.stateID,
				stateab:this.state.stateab,
				statebc:this.state.statebc,
				statemb:this.state.statemb,
				statenb:this.state.statenb,
				statenl:this.state.statenl,
				statent:this.state.statent,
				statens:this.state.statens							
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		} else if(this.state.taxindex==3)  {						
			if(!yearclaimcode) {
				alert('Please select tax year')
				return false
			}		
			if(!stateID) {
				alert('Please select Federal or Province')
				return false
			}
			const userObject={
				user:this.state.user,
				names:this.state.names,
				adminitem:this.state.adminitem,				
				taxindex:this.state.taxindex,					
				yearclaimcode: this.state.yearclaimcode,
				stateID:this.state.stateID,										
			}
			axios.post(process.env.REACT_APP_API_ENV+'api/addupdatesub.php', userObject)
				.then((res) => {						
					this.setState({Resp:res.data});											
				}).catch((error) => {
					console.log(error)
				});		
		}
	}
	
	render() {						
		return (		
			<div>      				
				<CommonHeader titles="Tax Index"/>
				<form onSubmit={this.handleSubmit}>
					<div>
						<label><h2>Manage BPA Formula</h2></label>							
						<select name="taxindex" id="taxindex" style={{width:'150px'}} value={this.state.taxindex} onChange={this.handleChange}>
							<option value="">--Manage BPA Formula--</option>
							<option value="1">Add Basic Personal Amount Formula</option>															
							<option value="2">Update Basic Personal Amount Formual</option>
							<option value="3">Remove Basic Personal Amount Formual</option>	
						</select><br/><br/>								
					</div>	
					{(()=>{if(this.state.taxindex != "") {return(<div>						
						<label><h2>Select Year</h2></label>							
						<select id="yearclaimcode" name="yearclaimcode" value = {this.state.yearclaimcode} style={{width:'160px'}} onChange={this.handleChange}>
							<option value="">--Select Year--</option>																	
							{dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 270 ? <option value={thisyear}>{thisyear}</option>:<option value={nextyear}>{nextyear}</option>}
						</select><br/>
						<h1>{this.state.taxindex==1 && dayjs().diff(dayjs(lastyear).endOf('year'),'day') < 183 ? thisyear+' Basic Personal Amount Formula should be set up before ' + thisyear + ' or at least in the first six months of '+thisyear:''}</h1><br/>						
						{(()=>{if(this.state.yearclaimcode != "") {return(<div>						
							<label><h2>Select Federal or Province</h2></label>							
							<select id="stateID" name="stateID" value = {this.state.stateID} style={{width:'160px'}} onChange={this.handleClaimChange}>
								<option value="">--Federal or Province--</option>																	
								{this.state.Provs.map((sub,index) => (<option value={sub[0]} key={index}>{sub[1]}</option>))}
							</select><br/><br/>
						</div>)}})()}						
					</div>)}})()}						
					{(()=>{if(this.state.taxindex != "" && this.state.stateID !="") {return(<div>						
						<h2>{this.state.AddIndex == "NO" ? "There is no BPA formula in the system, if year "+this.state.yearclaimcode+" for "+this.state.stateID+" has BAP formula required, please enter BPA Formula values":(this.state.taxindex == "3" ? "If below values are empty, please ignore, othewise delete them":"")}</h2><br/><br/>
						<div className="label-wrap">
							<div className="label">	
								<h2>BPA</h2>
								<h2>Maximum BPA</h2>
								<h2>Mimum BPA</h2>
								<h2>Max BPA - Income</h2>
								<h2>Min BPA - Income</h2>
								<h2>Factor-Nominator</h2>
								<h2>Factor-Denominator</h2>								
							</div>				
						</div>
						<div className="label-wrap">
							<div className="label">	
								<h2><input type="text" style={{width:'80px'}}  name="stateab" value={this.state.stateab} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statebc" value={this.state.statebc} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statemb" value={this.state.statemb} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statenb" value={this.state.statenb} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statenl" value={this.state.statenl} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statens" value={this.state.statens} onChange={this.handleChange}/></h2>
								<h2><input type="text" style={{width:'80px'}}  name="statent" value={this.state.statent} onChange={this.handleChange}/></h2>								
							</div>				
						</div><br/><br/><br/>						
						<h1>{this.state.Resp}</h1><br/><br/>
						</div>)}						
					})()}
					<h1>{this.state.indexcomms}</h1><br/><br/>
					<button type="submit" className="btn btn-primary mr-2" width="200px">&nbsp;Submit &nbsp;</button> 					
				</form>				
			</div>
		)
	}
}

export default setupPayRollYear;